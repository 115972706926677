<form
  [formGroup]="userGroup"
  class="flex-column"
  (ngSubmit)="addUser.emit(userGroup)"
>
  <section class="flex-column">
    <h3>Identifying Info</h3>
    <mat-divider class="margin-bottom"></mat-divider>
    <mat-form-field class="margin-sm-bottom">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName" required />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName" required />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Institution</mat-label>
      <mat-select formControlName="institution" name="institution">
        @for (institution of institutionData(); track institution.value) {
          <mat-option [value]="institution.value">{{
            institution.name
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Department</mat-label>
      <input matInput formControlName="department" required />
      <mat-error>Required</mat-error>
    </mat-form-field>
  </section>

  <section class="flex-column">
    <h3 class="margin-sm-top">Contact Info</h3>
    <mat-divider class="margin-bottom"></mat-divider>
    <mat-form-field class="margin-sm-bottom">
      <mat-label>Phone</mat-label>
      <input matInput mask="(000) 000-0000" formControlName="phone" required />
      @if (userGroup.get("phone")?.hasError("required")) {
        <mat-error>Required</mat-error>
      } @else {
        <mat-error>Invalid phone number</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Alternate Phone</mat-label>
      <input matInput mask="(000) 000-0000" formControlName="alternatePhone" />
      <mat-error>Invalid phone number</mat-error>
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" required />
      @if (userGroup.get("email")?.hasError("required")) {
        <mat-error>Required</mat-error>
      } @else {
        <mat-error>Invalid email</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="margin-sm-bottom">
      <mat-label>Alternate Email</mat-label>
      <input matInput formControlName="alternateEmail" />
      <mat-error>Invalid email</mat-error>
    </mat-form-field>
  </section>

  @if (state() === "affiliated") {
    <section class="flex-column margin-sm-bottom">
      <h3 class="margin-sm-top">System Options</h3>
      <mat-divider class="margin-sm-bottom"></mat-divider>
      <mat-checkbox formControlName="role"
        >Add reviewer as a General Counsel selection option</mat-checkbox
      >

      <mat-checkbox formControlName="active"
        >Not active in system (reviewer will not be able to log in to this
        site)</mat-checkbox
      >
    </section>

    <section class="flex-column">
      <h3 class="margin-sm-top">Email Notification</h3>
      <mat-divider class="margin-sm-bottom"></mat-divider>
      <mat-checkbox>Notify selected reviewer via email</mat-checkbox>
    </section>
  }

  @if (state() === "new" || state() === "edit") {
    <button
      mat-stroked-button
      type="submit"
      class="regents margin-sm-top"
      [disabled]="!userGroup.valid"
      extended
    >
      Add Reviewer
    </button>
  }
</form>
