<div class="margin-top margin-bottom width-md-75 margin-lr-auto">
  @if (state() === "dashboard") {
    <app-dashboard (stateChanged)="state.set('new-request')"></app-dashboard>
  } @else {
    <section class="width-100 margin-top margin-lr-auto">
      <h2>Add a New Public Records Request</h2>
      <mat-tab-group class="gray-border">
        <mat-tab label="Request Details">
          @if (state() === "new-request") {
            <app-new-request [newRequestForm]="requestGroup"></app-new-request>
          } @else if (state() === "request-details") {
            <app-request-details></app-request-details>
          }
        </mat-tab>
        <mat-tab label="Workflow">
          <section class="margin-top">
            <h3>Selected Reviewers</h3>
            <mat-divider class="margin-bottom"></mat-divider>
            <app-table [data]="reviewerData" [displayedColumns]="reviewerColumns"></app-table>
            <button
              mat-stroked-button
              class="regents"
            >
              Add Reviewer
            </button>
          </section>

          <section class="margin-top">
            <h3>General Counsel</h3>
            <mat-divider class="margin-bottom"></mat-divider>
            <app-table [data]="counselData" [displayedColumns]="counselColumns"></app-table>
            <button
              mat-stroked-button
              class="regents"
            >
              Add Counsel
            </button>
          </section>
        </mat-tab>
        <mat-tab label="Payment"> 
          <section class="margin-top">
            <h3>Payment</h3>
            <mat-divider class="margin-bottom"></mat-divider>
            <app-table [data]="paymentData" [displayedColumns]="paymentColumns"></app-table>
            <button
              mat-stroked-button
              class="regents"
            >
              Add Invoice
            </button>
          </section>

        </mat-tab>
      </mat-tab-group>
    </section>
  }
</div>
