<section>
  <div class="login">
    <div class="login-info">
      <h1>You have been logged out.</h1>
      <hr />
      <p>
        <a routerLink="/login">Log back in.</a>
      </p>
    </div>
  </div>
</section>
