import { Component, inject, signal, viewChild } from '@angular/core';
import { UserFormComponent } from '../user-form/user-form.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-reviewer-add',
  imports: [
    UserFormComponent,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatIcon,
  ],
  templateUrl: './reviewer-add.component.html',
  styleUrl: './reviewer-add.component.scss',
})
export class ReviewerAddComponent {
  data = signal(inject(MAT_DIALOG_DATA));
  dialog = inject(MatDialog);

  userForm = viewChild<UserFormComponent>('userForm');

  addUser(form: FormGroup): void {
    this.data().reviewers.push(form.value);
    this.data().reviewers.forEach((item: any) => {
      item.reviewer = item.firstName + ' ' + item.lastName;
    });
    this.data().reviewerTable().renderRows();
  }
}
