<div id="toolbar-nav-container">
  <mat-toolbar class="tabs">
    <mat-nav-list
        aria-label="main-navigation"
        class="desktop-list flex-row inline-flex tabs"
    >
        @for (navListItem of navList(); track navListItem.id) {
            <a
                mat-list-item
                title="{{ navListItem.title }}"
                [routerLink]="[navListItem.routerLink]"
                routerLinkActive="isActive"
            >
                {{ navListItem.name }}
            </a>
        }
    </mat-nav-list>
</mat-toolbar>
</div>
