import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment.development';
import { Request } from '../../models/request';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private http = inject(HttpClient)

  requestsUrl = environment.apiUrl + environment.requestsEndpoint

  postRequest(form: FormGroup<any> | undefined): void {
    try {
        this.http
            .post<Request>(this.requestsUrl, form?.value, {
                observe: 'response',
            })
            .subscribe((res) => {
                console.warn('Status', res.status)
            })
    } catch (e) {
        console.warn('RequestService::postRequest: ', e)
    }
  }

  patchRequest(form: FormGroup): void {
    try {
        this.http
            .patch<Request>(this.requestsUrl, form.value, {
                observe: 'response',
            })
            .subscribe((res) => {
                console.warn('Status', res.status)
            })
    } catch (e) {
        console.warn('RequestService::patchRequest: ', e)
    }
  }

  deleteRequest(form: FormGroup): void {
    try {
        this.http
            .delete<Request>(this.requestsUrl, form.value)
            .subscribe((res) => {

            })
    } catch (e) {
        console.warn('RequestService::deleteRequest: ', e)
    }
  }

  getRequests(): Observable<Request[]> {
    return this.http.get<Request[]>(
        this.requestsUrl
    )
  }
}
