@if (userService.institution()?.key === "uiowa") {
  <csi-ng-brand-header
    [showToolbar]="authenticated"
    [showUser]="authenticated"
    [navList]="navList"
    [site]="siteName"
  >
  </csi-ng-brand-header>
} @else {
  <div class="brand-bar-container">
    <mat-toolbar
      class="flex-row center"
      [class]="userService.institution()?.key"
      [class.regents-bar]="userService.institution()?.key === 'regents'"
    >
      @if (
        userService.institution()?.key === "iastate" ||
        userService.institution()?.key === "uni"
      ) {
        <span class="width-100">
          <span class="flex-row flex-start bar-content">
            <span
              class="flex-column center margin-xsm-top"
              [class.margin-xsm-top]="userService.institution()?.key === 'uni'"
            >
              <a
                href="{{ userService.institution()?.siteLink }}"
                target="_blank"
              >
                <img
                  [src]="
                    'assets/images/' +
                    userService.institution()?.key +
                    '-logo.png'
                  "
                  [alt]="userService.institution()?.key?.toUpperCase()"
                  width="224"
                />
              </a>
            </span>

            <a
              href="{{ siteName.siteUrl }}"
              target="_blank"
              class="site-name margin-left flex-column center"
              [class.white]="userService.institution()?.key === 'uni'"
              [class.black]="userService.institution()?.key === 'iastate'"
            >
              <h1 [class]="userService.institution()?.key">
                {{ siteName.siteName }}
              </h1>
            </a>
          </span>
        </span>
      } @else if (userService.institution()?.key === "regents") {
        <span class="width-100 margin-sm-top">
          <span class="flex-row flex-start">
            <span class="flex-column center">
              <a href="/" target="_blank">
                <img
                  class="logo margin-top mat-elevation-z4"
                  src="assets/images/regents-logo-1.jpg"
                  alt="Iowa Board of Regents Logo"
                  width="116"
                />
              </a>
            </span>

            <span class="flex-column center site-name-wrapper">
              <a
                href="{{ siteName.siteUrl }}"
                target="_blank"
                class="site-name margin-left flex-column center"
              >
                <h1 class="white regents">{{ siteName.siteName }}</h1>
              </a>
            </span>
          </span>
        </span>
      }
      @if (authenticated) {
      <span class="flex-row">
        <p class="white margin-right user">{{ accountName }}</p>
        <span class="flex-column center">
          <button
            mat-stroked-button
            class="logout-button black"
            (click)="logout()"
          >
            Log Out
          </button>
          <!-- <ng-template #loginBtn>
            <button
              mat-stroked-button
              class="logout-button black"
              (click)="login()"
            >
              Log in
            </button>
          </ng-template> -->
        </span>
      </span>
    }
    </mat-toolbar>
  </div>
  @if (authenticated) {
    <app-tabs></app-tabs>
  }
}
