<h2>Dashboard</h2>
@if (route === "intake" || route === "settings") {
  <button mat-button (click)="stateChanged.emit()">
    {{ buttonLabel() }}
  </button>
}
<app-search-input></app-search-input>
<app-button-toggle-group></app-button-toggle-group>
<app-expansion-panel></app-expansion-panel>
<app-table></app-table>
