import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ReviewerAddComponent } from '../reviewer-add/reviewer-add.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-reviewer-dialog',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatOptionModule,
    MatLabel,
    MatDivider,
    MatDialogClose,
    MatDialogActions,
    MatDialogContent,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIcon,
  ],
  templateUrl: './reviewer-dialog.component.html',
  styleUrl: './reviewer-dialog.component.scss',
})
export class ReviewerDialogComponent {
  dialog = inject(MatDialog);
  data = signal(inject(MAT_DIALOG_DATA));

  reviewer = new FormControl<any>(null);

  state: WritableSignal<string> = signal('');

  title: Signal<string> = computed(() =>
    this.state() === 'affiliated'
      ? 'Create a new reviewer affiliated with the institution'
      : 'Add a reviewer not affiliated with the institution',
  );

  subtitle: Signal<string> = computed(() =>
    this.state() === 'affiliated'
      ? 'This person will be added to the database for future requests.'
      : 'This person will only be listed for this request.',
  );

  get reviewerLabel(): string {
    return this.data().allDataAdded(
      this.data().reviewerData,
      this.data().reviewers,
    )
      ? 'All reviewers added'
      : 'Reviewer';
  }

  openDialog(state: string) {
    this.state.set(state);
    this.dialog.open(ReviewerAddComponent, {
      height: 'auto',
      autoFocus: 'first-header',
      data: {
        reviewers: this.data().reviewers,
        reviewerTable: this.data().reviewerTable,
        reviewerData: this.data().reviewerData,
        institutionData: this.data().institutionData,
        roleData: this.data().roleData,
        state: this.state(),
        title: this.title(),
        subtitle: this.subtitle(),
      },
    });
  }

  addUser(control: FormControl): void {
    this.data().reviewers.push(control);
    this.data().reviewers.forEach((item: any) => {
      item.reviewer = item.firstName + ' ' + item.lastName;
    });
    this.data().reviewerTable().renderRows();
    this.reviewer.reset();
  }
}
