import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TabsComponent } from './shared/components/tabs/tabs.component';
import { BrandBarComponent } from './shared/components/brand-bar/brand-bar.component';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, BrandBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'pr-workflow';
}
