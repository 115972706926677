import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { Category } from '../../models/category';
import { Institution } from '../../models/institution';
import { ResponseTimeCount } from '../../models/response-time-count';
import { Status } from '../../models/status';
import { State } from '../../models/state';
import { User } from '../../models/user';
import { Affilliation } from '../../models/affilliation';
import institutions from '../../../../../public/assets/data/institutions.json';
import categories from '../../../../../public/assets/data/categories.json';
import statuses from '../../../../../public/assets/data/statuses.json';
import states from '../../../../../public/assets/data/states.json';
import affilliations from '../../../../../public/assets/data/affilliations.json';
import responseTimeCounts from '../../../../../public/assets/data/response-time-counts.json';

@Component({
  selector: 'app-table',
  imports: [
    MatTableModule,
    MatSortModule,
    DatePipe,
    MatIcon,
    MatButtonModule,
    MatPaginatorModule,
    CurrencyPipe,
    MatMenuModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnInit, AfterViewInit {
  institutionData: Institution[] = institutions;
  affilliationData: Affilliation[] = affilliations;
  categoryData: Category[] = categories;
  stateData: State[] = states;
  statusData: Status[] = statuses;
  responseTimeCountData: ResponseTimeCount[] = responseTimeCounts;

  reviewerData = input<User[]>();
  counselData = input<User[]>();

  invoiceForm = input<any>();
  table = viewChild<MatTable<any>>(MatTable);

  title = input<string>();
  data = input<any>();
  canDelete = input<boolean>(false);
  canEdit = input<boolean>(false);
  displayedColumns = input<Array<string>>();
  filterGroup = input<any>();

  getDetails = output<string>();
  setRequest = output<string>();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  tableData: Array<any> = [];

  readonly paginator = viewChild.required(MatPaginator);
  readonly sort = viewChild.required(MatSort);

  ngOnInit() {
    this.tableData = this.data();
    this.dataSource = new MatTableDataSource(this.tableData);

    if (this.canDelete()) {
      this.enableDelete();
    }

    if (this.canEdit()) {
      this.enableEdit();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort();
    this.dataSource.paginator = this.paginator();

    const filteredValues = {
      title: '',
      description: '',
      status: '',
      institution: '',
      responseTimeCount: '',
      category: '',
      dateCompleted: '',
      dateInitiated: '',
      state: '',
      reviewer: '',
      counsel: '',
      affilliation: '',
      requester: '',
    };

    this.filterGroup()?.valueChanges.subscribe((form: any) => {
      this.dataSource.filter = JSON.stringify(filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  changeState(state: string, request: string): void {
    this.setRequest.emit(request);
    this.getDetails.emit(state);
  }

  enableDelete(): void {
    this.displayedColumns()?.push('delete');
  }

  enableEdit(): void {
    this.displayedColumns()?.push('edit');
  }

  camelToTitleCase(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  renderRows() {
    this.tableData = this.data();
    this.dataSource = new MatTableDataSource(this.tableData);
    this.table()?.renderRows();
  }

  isDate(value: any): boolean {
    return value instanceof Date;
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isCurrency(value: any): boolean {
    const currencyRegex = /^\d{1,3}(,\d{3})*(\.\d{2})?$/;
    return currencyRegex.test(value);
  }

  getColumnHeader(column: any): string {
    let result: string = '';
    if (column !== 'delete' && column !== 'edit') {
      if (column === 'state') {
        result = 'Internal Status';
      }
      if (column === 'reviewer' && this.title() === 'requests') {
        result = 'Reviewer(s)';
      } else if (
        column !== 'state' &&
        (column !== 'reviewer' || this.title() !== 'requests')
      ) {
        result = this.camelToTitleCase(column);
      }
    }
    return result;
  }

  getColumnValues(element: any, column: any): string | undefined {
    let result: string | undefined = '';
    if (column === 'counsel' && this.title() === 'requests') {
      result = this.getCounselName(element[column]);
    } else if (column === 'counsel' && this.title() === 'counsel') {
      result = this.getCounselName(element.username);
    } else if (column === 'reviewer' && this.title() === 'requests') {
      result = this.getReviewerName(element[column]).join(', ');
    } else if (this.isNumber(element[column])) {
      result = this.getName(element[column], column);
    } else {
      result = element[column];
    }
    return result;
  }

  getCounselName(id: string): string {
    let result: string = '';
    let firstName = this.counselData()?.find(
      (item: User) => item.username === id,
    )?.firstName;
    let lastName = this.counselData()?.find(
      (item: User) => item.username === id,
    )?.lastName;
    result = firstName + ' ' + lastName;
    return result;
  }

  getReviewerName(reviewers: Array<string>): Array<string> {
    let result: Array<string> = [];
    reviewers.forEach((reviewer: string) => {
      let firstName = this.reviewerData()?.find(
        (item: User) => item.username === reviewer,
      )?.firstName;
      let lastName = this.reviewerData()?.find(
        (item: User) => item.username === reviewer,
      )?.lastName;
      result.push(firstName + ' ' + lastName);
    });
    return result;
  }

  getName(id: number, column: string): string | undefined {
    let result: string | undefined = '';
    if (column === 'institution') {
      result = this.institutionData.find(
        (item: Institution) => item.value === id,
      )?.name;
    }
    if (column === 'status') {
      result = this.statusData.find((item: Status) => item.value === id)?.name;
    }
    if (column === 'state') {
      result = this.stateData.find((item: State) => item.value === id)?.name;
    }
    if (column === 'affilliation') {
      result = this.affilliationData.find(
        (item: Affilliation) => item.value === id,
      )?.name;
    }
    return result;
  }

  deleteRow(row: any): void {
    this.tableData.splice(this.tableData.indexOf(row), 1);
    this.dataSource = new MatTableDataSource(this.tableData);
    this.renderRows();
  }

  editRow(row: any): void {
    this.deleteRow(row);
    this.invoiceForm().invoiceGroup.setValue(row);
  }

  customFilterPredicate() {
    const filterPredicate = (data: any, filter: string): boolean => {
      let match = true;
      if (this.filterGroup().get('search')?.value) {
        match =
          data.title
            .toLowerCase()
            .includes(this.filterGroup().get('search')?.value) ||
          data.description
            .toLowerCase()
            .includes(this.filterGroup().get('search')?.value);
      }
      if (this.filterGroup().get('status')?.value) {
        match =
          match &&
          this.filterGroup()
            .get('status')
            ?.value.toString()
            .includes(data.status);
      }
      if (
        this.filterGroup().get('institution')?.value &&
        this.filterGroup().get('institution')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('institution')
            ?.value.toString()
            .includes(data.institution);
      }
      if (
        this.filterGroup().get('responseTimeCount')?.value &&
        this.filterGroup().get('responseTimeCount')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('responseTimeCount')
            ?.value.toString()
            .includes(data.responseTimeCount);
      }
      if (
        this.filterGroup().get('category')?.value &&
        this.filterGroup().get('category')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('category')
            ?.value.toString()
            .includes(data.category);
      }
      if (
        this.filterGroup().get('reviewer')?.value &&
        this.filterGroup().get('reviewer')?.value.length > 0
      ) {
        match = this.filterGroup()
          .get('reviewer')
          ?.value.some((item: string) => data.reviewer.includes(item));
      }
      if (
        this.filterGroup().get('state')?.value &&
        this.filterGroup().get('state')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('state')
            ?.value.toString()
            .includes(data.state);
      }
      if (
        this.filterGroup().get('counsel')?.value &&
        this.filterGroup().get('counsel')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('counsel')
            ?.value.toString()
            .includes(data.counsel);
      }
      if (
        this.filterGroup().get('affilliation')?.value &&
        this.filterGroup().get('affilliation')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('affilliation')
            ?.value.toString()
            .includes(data.affilliation);
      }
      if (this.filterGroup().get('dateCompletedStart')?.value) {
        match =
          match &&
          this.filterGroup().get('dateCompletedStart')?.value <=
            new Date(data.dateCompleted);
      }
      if (this.filterGroup().get('dateCompletedEnd')?.value) {
        match =
          match &&
          this.filterGroup().get('dateCompletedEnd')?.value >=
            new Date(data.dateCompleted);
      }
      if (this.filterGroup().get('dateInitiatedStart')?.value) {
        match =
          match &&
          this.filterGroup().get('dateInitiatedStart')?.value <=
            new Date(data.dateInitiated);
      }
      if (this.filterGroup().get('dateInitiatedEnd')?.value) {
        match =
          match &&
          this.filterGroup().get('dateInitiatedEnd')?.value >=
            new Date(data.dateInitiated);
      }
      return match;
    };
    return filterPredicate;
  }
}
