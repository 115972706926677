import {
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewRequestComponent } from '../shared/components/new-request/new-request.component';
import { RequestDetailsComponent } from '../shared/components/request-details/request-details.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { TableComponent } from "../shared/components/widgets/table/table.component";
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-intake',
  imports: [
    DashboardComponent,
    NewRequestComponent,
    RequestDetailsComponent,
    MatTab,
    MatTabGroup,
    TableComponent,
    MatButton,
    MatDivider
],
  templateUrl: './intake.component.html',
  styleUrl: './intake.component.scss',
})
export class IntakeComponent {
  fb = inject(FormBuilder);
  private location = inject(Location);

  state: WritableSignal<string> = signal('dashboard');

  route: string = this.location.path().split('/')[1];

  reviewerData = [
    {reviewer: 'Test Reviewer', department: 'Test Department'},
    {reviewer: 'Test Reviewer', department: 'Test Department'}
  ];

  reviewerColumns: string[] = [
    'reviewer',
    'department'
  ]

  counselData = [
    {counsel: 'Test Counsel'},
    {counsel: 'Test Counsel'}
  ];

  counselColumns: string[] = [
    'counsel'
  ]

  paymentData = [
    {invoiceAmount: 'Test Invoice Amount', invoiceSent: 'Test Invoice Sent', paymentRecieved: 'Test Payment Recieved'},
    {invoiceAmount: 'Test Invoice Amount', invoiceSent: 'Test Invoice Sent', paymentRecieved: 'Test Payment Recieved'}  ];

  paymentColumns: string[] = [
    'invoiceAmount',
    'invoiceSent',
    'paymentRecieved'
  ]

  data = [
    {requestTitle: 'Test', requester: 'Test'},
    {requestTitle: 'Test', requester: 'Test'}
  ];

  displayedColumns: string[] = [
    'requestTitle',
    'requester'
  ]

  requestGroup: FormGroup = this.fb.group(
    {
      requesterGroup: this.fb.group({
        requesterFirstName: ['', Validators.required],
        requesterLastName: ['', Validators.required],
        requesterEmail: ['', [Validators.required, Validators.email]],
        affiliation: ['', Validators.required],
      }),
      requestGroup: this.fb.group({
        institution: ['', Validators.required],
        title: ['', Validators.required],
        description: [
          '',
          [Validators.required, Validators.minLength(10)],
        ],
        category: ['', Validators.required],
        state: ['', Validators.required],
        dateInitiated: ['', Validators.required],
        reviewerDateDue: ['', Validators.required],
        dateDue: ['', Validators.required],
      }),
      publicationGroup: this.fb.group({
        status: ['', Validators.required],
        dateCompleted: ['', Validators.required],
        responseTimeCount: ['', Validators.required],
      }),
    },
    { updateOn: 'blur' },
  );
}
