
[
    {
        "value": 0,
        "name": "Application Administrator"
    },
    {
        "value": 1,
        "name": "Board Counsel/Exec Dir."
    },
    {
        "value": 2,
        "name": "University Administrator"
    },
    {
        "value": 3,
        "name": "Transparency Officer"
    },
    {
        "value": 4,
        "name": "Reviewer"
    },
    {
        "value": 5,
        "name": "Counsel"
    }
]