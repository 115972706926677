[
    {
        "value": 0,
        "name": "New"
    },
    {
        "value": 1,
        "name": "In progress"
    },
    {
        "value": 2,
        "name": "Awaiting data"
    },
    {
        "value": 3,
        "name": "Under review for trade secret"
    },
    {
        "value": 4,
        "name": "Awaiting payment"
    },
    {
        "value": 5,
        "name": "Awaiting requester's clarification"
    },
    {
        "value": 6,
        "name": "Complete"
    },
    {
        "value": 7,
        "name": "Closed for non-payment"
    },
    {
        "value": 8,
        "name": "Closed for requester's non-response"
    },
    {
        "value": 9,
        "name": "Request withdrawn"
    },
    {
        "value": 10,
        "name": "Closed for insufficient request details"
    },
    {
        "value": 11,
        "name": "DENIED - 22.7(1) - and/or FERPA/Education Records"
    },
    {
        "value": 12,
        "name": "DENIED - 22.7(2) - and/or HIPAA, Health Info/Medical Records"
    },
    {
        "value": 13,
        "name": "DENIED - 22.7 (11) - Confidential Personnel Records"
    },
    {
        "value": 14,
        "name": "DENIED - 22.7 (3) - Proprietary Information"
    },
    {
        "value": 15,
        "name": "DENIED - 22.7 (3)- Trade Secrets"
    },
    {
        "value": 16,
        "name": "DENIED - 22.7 (4) -  Attorney Work Product"
    },
    {
        "value": 17,
        "name": "DENIED - 22.7 (5) - Police Investigative Reports"
    },
    {
        "value": 18,
        "name": "DENIED - 22.7(6) - Reports which would give advantage to competitors"
    },
    {
        "value": 19,
        "name": "DENIED - 22.7(13) - Library Records Identifying Patron"
    },
    {
        "value": 20,
        "name": "DENIED - 22.7(18) - Confidential Communications from Outside Government"
    },
    {
        "value": 21,
        "name": "DENIED - 22.7(19)- Examinations"
    },
    {
        "value": 22,
        "name": "DENIED - 22.7(41) - Medical Examiner Records/Reports"
    },
    {
        "value": 23,
        "name": "DENIED - 22.7(50) - Security Procedures/ Emergency Preparedness Info"
    },
    {
        "value": 24,
        "name": "DENIED - 22.7(52) - Confidential Donor Records"
    },
    {
        "value": 25,
        "name": "DENIED - 22.7(55) - Intelligence Assessment/Data"
    },
    {
        "value": 26,
        "name": "DENIED - 22.7(60) - Records Protected by Chapter 21 Closed Session"
    },
    {
        "value": 27,
        "name": "DENIED - 22.7(65) - Draft or Research Material"
    },
    {
        "value": 28,
        "name": "DENIED - Copyrighted Materials"
    },
    {
        "value": 29,
        "name": "DENIED - Active Bid or No Award"
    },
    {
        "value": 30,
        "name": "DENIED - Attorney-Client Privilege"
    }
]