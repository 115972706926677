import { Component, computed, inject, input, Signal } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Institution } from '../../models/institution';
import { User } from '../../models/user';
import { Category } from '../../models/category';
import { ResponseTimeCount } from '../../models/response-time-count';
import { State } from '../../models/state';
import { Status } from '../../models/status';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { Affilliation } from '../../models/affilliation';
import institutions from '../../../../../public/assets/data/institutions.json';
import categories from '../../../../../public/assets/data/categories.json';
import statuses from '../../../../../public/assets/data/statuses.json';
import states from '../../../../../public/assets/data/states.json';
import affilliations from '../../../../../public/assets/data/affilliations.json';
import responseTimeCounts from '../../../../../public/assets/data/response-time-counts.json';

@Component({
  selector: 'app-filter-form',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatDatepickerModule,
  ],
  templateUrl: './filter-form.component.html',
  styleUrl: './filter-form.component.scss',
})
export class FilterFormComponent {
  fb = inject(FormBuilder);
  institutionData: Institution[] = institutions;
  affilliationData: Affilliation[] = affilliations;
  categoryData: Category[] = categories;
  stateData: State[] = states;
  statusData: Status[] = statuses;
  responseTimeCountData: ResponseTimeCount[] = responseTimeCounts;
  reviewerData = input<User[]>();
  counselData = input<User[]>();
  user = input<User>();

  filterGroup: FormGroup = this.fb.group({
    search: [''],
    status: [''],
    institution: [''],
    responseTimeCount: [''],
    category: [''],
    reviewer: [[]],
    counsel: [''],
    state: [''],
    affilliation: [''],
    dateCompletedStart: new FormControl<Date | null>(null),
    dateCompletedEnd: new FormControl<Date | null>(null),
    dateInitiatedStart: new FormControl<Date | null>(null),
    dateInitiatedEnd: new FormControl<Date | null>(null),
  });

  canFilterStatus: Signal<boolean> = computed(() => {
    return this.user()?.role === 0;
  });
}
