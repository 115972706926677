import { afterNextRender, Component, inject, Injector, input, signal, viewChild, WritableSignal } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { RequestService } from '../../services/request/request.service';
import { MatDivider } from '@angular/material/divider';
import { MatOption, MatSelect } from '@angular/material/select';
import { Institution } from '../../models/institution';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { TextFieldModule } from '@angular/cdk/text-field'
import { MatDatepickerModule } from '@angular/material/datepicker';
import {provideNativeDateAdapter} from '@angular/material/core';


@Component({
  selector: 'app-new-request',
  providers: [provideNativeDateAdapter()],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatLabel,
    MatInput,
    MatDivider,
    MatSelect,
    MatOption,
    TextFieldModule,
    MatDatepickerModule
  ],
  templateUrl: './new-request.component.html',
  styleUrl: './new-request.component.scss',
})
export class NewRequestComponent {
  requestService = inject(RequestService)
  fb = inject(FormBuilder)

  private _injector = inject(Injector)

  readonly autosize = viewChild.required<CdkTextareaAutosize>('autosize')

  newRequestForm = input<any>()

  submitted: WritableSignal<boolean> = signal(false)

  institutions: Institution[] = [
    {key: 'regents', name: 'Iowa Board of Regents'},
    {key: 'uiowa', name: 'The University of Iowa'},
    {key: 'uni', name: 'The University of Northern Iowa'},
    {key: 'iastate', name: 'Iowa State University'}
  ];

  onSubmit(): void {
    this.requestService.postRequest(this.newRequestForm())
    this.submitted.set(true)
  }

  triggerResize(): void {
    // Wait for content to render, then trigger textarea resize
    afterNextRender(
        () => {
            this.autosize().resizeToFitContent(true)
        },
        {
            injector: this._injector,
        }
    )
}

}
