[
        {
            "value": 0,
            "key": "regents",
            "name": "Iowa Board of Regents",
            "phone": "515-281-3934",
            "email": "",
            "privacyLink": "https://www.iowaregents.edu/the-board/privacy",
            "paymentLink": "",
            "siteLink": "https://www.iowaregents.edu/"
        },
        {
            "value": 1,
            "key": "uiowa",
            "name": "The University of Iowa",
            "phone": "319-335-0270",
            "email": "public-records@uiowa.edu",
            "privacyLink": "https://uiowa.edu/privacy",
            "paymentLink": "https://commerce.cashnet.com/storefront/UIOWAPUBLICRECORDS",
            "siteLink": "https://uiowa.edu"
        },
        {
            "value": 2,
            "key": "uni",
            "name": "The University of Northern Iowa",
            "phone": "319-273-2311",
            "email": "publicrecords@uni.edu",
            "privacyLink": "https://policies.uni.edu/web-privacy-statement",
            "paymentLink": "https://uni.edu",
            "siteLink": "https://uni.edu"
        },
        {
            "value": 3,
            "key": "iastate",
            "name": "Iowa State University",
            "phone": "515-294-7958",
            "email": "publicrecords@iastate.edu",
            "privacyLink": "https://www.lib.iastate.edu/about-us/policies/privacy-policy",
            "paymentLink": "https://iastate.edu",
            "siteLink": "https://iastate.edu"
        }
]