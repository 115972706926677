@if (showInvoiceForm()) {
  <form
    [formGroup]="invoiceGroup"
    class="flex-column margin-top margin-bottom regents"
    (ngSubmit)="addInvoice.emit(invoiceGroup)"
  >
    <div class="flex-row space-around">
      <mat-form-field class="width-auto">
        <mat-label class="margin-left">Amount</mat-label>
        <span matTextPrefix>$&nbsp;</span>
        <input
          matInput
          formControlName="amount"
          type="text"
          mask="separator.2"
          thousandSeparator=","
          decimalMarker="."
          required
        />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="width-sm-50 padding-left">
        <mat-label>Date Sent</mat-label>
        <input
          matInput
          formControlName="dateSent"
          [matDatepicker]="dateSentPicker"
        />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="dateSentPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateSentPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="width-sm-50 padding-left">
        <mat-label>Date Recieved</mat-label>
        <input
          matInput
          formControlName="dateRecieved"
          [matDatepicker]="dateRecievedPicker"
        />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="dateRecievedPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateRecievedPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      type="submit"
      class="secondary-button margin-sm-top"
      [disabled]="!invoiceGroup.valid"
      extended
    >
      Add Invoice
    </button>
  </form>
}
