import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NavList } from '../../models/nav-list';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-tabs',
  imports: [
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatButtonToggleModule,
    RouterModule,
  ],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent {
  userService = inject(UserService);
  readonly navList = input<NavList[]>([
    {
      id: '1',
      title: 'Intake & Response',
      name: 'Intake & Response',
      hrefLink: '',
      routerLink: '/intake',
      clickLink: ''
    },
    {
      id: '2',
      title: 'Publication',
      name: 'Publication',
      hrefLink: '',
      routerLink: '/publication',
      clickLink: ''
    },
    {
      id: '3',
      title: 'System Settings',
      name: 'System Settings',
      hrefLink: '',
      routerLink: '/settings',
      clickLink: ''
    },
  ]);
}
