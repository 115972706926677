<form
    [formGroup]="newRequestForm()"
    (ngSubmit)="onSubmit()"
    class="flex-column"
    class="regents"
>

    <section formGroupName="requesterGroup" class="flex-column margin-top">
        <h3>Requester</h3>
        <mat-divider class="margin-bottom"></mat-divider>
        <span class="flex-row space-between">
            <mat-form-field class="width-sm-50 margin-sm-bottom">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="requesterFirstName" required />
                <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field class="width-sm-50 margin-sm-bottom padding-left">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="requesterLastName" required />
                <mat-error>Required</mat-error>
            </mat-form-field>
        </span>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Email</mat-label>
            <input matInput formControlName="requesterEmail" required />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Affiliation</mat-label>
            <input matInput formControlName="affiliation" required />
            <mat-error>Required</mat-error>
        </mat-form-field>
    </section>

    <section formGroupName="requestGroup" class="flex-column">
        <h3>Request</h3>
        <mat-divider class="margin-bottom"></mat-divider>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Institution</mat-label>
            <mat-select formControlName="institution" name="institution">
              @for (institution of institutions; track institution.key) {
                <mat-option [value]="institution.key">{{institution.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

        <mat-form-field class="width-100 margin-sm-bottom">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" required />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="width-md-75 margin-sm-bottom">
            <mat-label>Description</mat-label>
            <textarea
                formControlName="description"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="10"
                cdkAutosizeMaxRows="50"
            ></textarea>
            <mat-error
                >Request must be at least 10 characters long</mat-error
            >
        </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Category</mat-label>
            <mat-select formControlName="category" name="category">
              @for (institution of institutions; track institution.key) {
                <mat-option [value]="institution.key">{{institution.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" name="state">
              @for (institution of institutions; track institution.key) {
                <mat-option [value]="institution.key">{{institution.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Date Initiated</mat-label>
            <input matInput formControlName="dateInitiated" [matDatepicker]="dateInitiatedPicker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dateInitiatedPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateInitiatedPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Reviewer Date Due</mat-label>
            <input matInput formControlName="reviewerDateDue" [matDatepicker]="reviewerDateDuePicker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="reviewerDateDuePicker"></mat-datepicker-toggle>
            <mat-datepicker #reviewerDateDuePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Date Due</mat-label>
            <input matInput formControlName="dateDue" [matDatepicker]="dateDuePicker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dateDuePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateDuePicker></mat-datepicker>
        </mat-form-field>
    </section>

    <section formGroupName="publicationGroup" class="flex-column">
        <h3>Publication</h3>
        <mat-divider class="margin-bottom"></mat-divider>
        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" name="status">
              @for (institution of institutions; track institution.key) {
                <mat-option [value]="institution.key">{{institution.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Date Completed</mat-label>
            <input matInput formControlName="dateCompleted" [matDatepicker]="dateCompleted">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dateCompleted"></mat-datepicker-toggle>
            <mat-datepicker #dateCompleted></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width-sm-50 margin-sm-bottom">
            <mat-label>Time Count</mat-label>
            <mat-select formControlName="responseTimeCount" name="responseTimeCount">
              @for (institution of institutions; track institution.key) {
                <mat-option [value]="institution.key">{{institution.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
    </section>

    <button
        mat-stroked-button
        class="regents"
    >
        Cancel
    </button>

    <button
        mat-stroked-button
        type="submit"
        class="regents"
        [disabled]="!newRequestForm().valid"
        extended
    >
        Add Request
    </button>
</form>
