<section class="width-100 margin-lr-auto content">
  <span class="flex-row space-between padding-top padding-bottom">
    <h2>
      {{ heading() }}
    </h2>

    <span class="flex-row flex-end">
      @if (route === "intake" || route === "settings") {
        @if (canAddRequest()) {
          <button
            mat-flat-button
            (click)="changeState('new-request')"
            class="flex-row space-between secondary-button"
          >
            <mat-icon class="material-symbols-outlined"> add </mat-icon>
            <span class="flex-column center margin-sm-left">
              {{ buttonLabel() }}
            </span>
          </button>
        }
        <button
          mat-stroked-button
          class="regents flex-row space-between margin-sm-left"
        >
          <mat-icon class="material-symbols-outlined"> download </mat-icon>
          <span class="flex-column center margin-sm-left">
            Export Results to Excel
          </span>
        </button>
      }
    </span>
  </span>
  <app-filter-form
    #filterForm
    [reviewerData]="reviewerData()"
    [counselData]="counselData()"
    [user]="user()"
  ></app-filter-form>
  <app-table
    #requestTable
    [data]="data()"
    [displayedColumns]="displayedColumns()"
    [counselData]="counselData()"
    [reviewerData]="reviewerData()"
    (setRequest)="setRequest($event)"
    (getDetails)="changeState($event)"
    [filterGroup]="filterForm.filterGroup"
    [title]="'requests'"
  ></app-table>
</section>
