import { Routes } from '@angular/router';
import { IntakeComponent } from './intake/intake.component';
import { PublicationComponent } from './publication/publication.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from './shared/guards/authorization/authorization.guard';
import { LoginComponent } from './shared/components/login/login.component';
import { LoggedOutComponent } from './shared/components/logged-out/logged-out.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'intake',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      { path: 'begin', canActivate: [AuthGuard], component: SettingsComponent },
    ],
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
  {
    path: 'intake',
    canActivate: [ AuthGuard ],
    title: 'Intake & Response',
    component: IntakeComponent,
  },
  {
    path: 'intake/:id',
    title: 'Intake & Response',
    component: IntakeComponent,
  },
  {
    path: 'publication',
    canActivate: [AuthGuard],
    title: 'Publication',
    component: PublicationComponent,
  },
  {
    path: 'settings',
    title: 'System Settings',
    canActivate: [AuthGuard],
    component: SettingsComponent,
  },
  {
    path: '**',
    redirectTo: 'intake',
  },
];
