<span class="flex-row space-between">
  <h3 mat-dialog-title autofocus class="margin">Add Reviewer</h3>
  <button
    mat-button
    class="regents margin-sm-top margin-sm-right"
    extended
    matDialogClose
  >
    <mat-icon class="material-symbols-outlined margin-lr-auto">
      close
    </mat-icon>
  </button>
</span>
<mat-dialog-content class="flex-column">
  <h3>Select an existing reviewer</h3>
  <mat-form-field class="width-100 margin-sm-top">
    <mat-label>{{ reviewerLabel }}</mat-label>
    <mat-select
      name="reviewer"
      [formControl]="reviewer"
      [disabled]="data().allDataAdded(data().reviewerData, data().reviewers)"
    >
      @for (reviewer of data().reviewerData; track reviewer.username) {
        @if (!data().reviewers.includes(reviewer)) {
          <mat-option [value]="reviewer"
            >{{ reviewer.firstName }} {{ reviewer.lastName }}</mat-option
          >
        }
      }
    </mat-select>
  </mat-form-field>
  <mat-checkbox
    class="margin-sm-bottom"
    [disabled]="data().allDataAdded(data().reviewerData, data().reviewers)"
    >Notify selected reviewer via email</mat-checkbox
  >

  <button
    mat-stroked-button
    class="regents"
    (click)="addUser(reviewer.value)"
    [disabled]="data().allDataAdded(data().reviewerData, data().reviewers)"
  >
    Add Selected Reviewer
  </button>
  <mat-divider class="margin-top margin-bottom"></mat-divider>
  <h3>Create a new reviewer affiliated with the institution</h3>
  <p class="margin-sm-bottom">
    This person may be selected as a reviwer for future requests.
  </p>
  <button
    mat-stroked-button
    class="regents"
    extended
    (click)="openDialog('affiliated')"
  >
    Create a new institutional reviewer
  </button>

  <mat-divider class="margin-top margin-bottom"></mat-divider>

  <h3>Add a reviewer not affiliated with the institution</h3>
  <p class="margin-sm-bottom">
    This person will only be listed for this request.
  </p>
  <button
    mat-stroked-button
    class="regents margin-bottom"
    extended
    (click)="openDialog('non-affiliated')"
  >
    Add a non-affiliated reviewer
  </button>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="regents" extended matDialogClose>
    Cancel
  </button>
</mat-dialog-actions>
