import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';

@Component({
  selector: 'app-publication',
  imports: [MatTabsModule, DashboardComponent],
  templateUrl: './publication.component.html',
  styleUrl: './publication.component.scss',
})
export class PublicationComponent {}
