<div
  class="margin-top margin-bottom width-md-75 margin-lr-auto elevated-container padding"
>
  @if (state() === "dashboard") {
    <app-dashboard
      [user]="userService.user"
      [data]="requestData"
      [displayedColumns]="requestColumns"
      [reviewerData]="reviewerData"
      [counselData]="counselData"
      (stateChanged)="setState($event)"
      (request)="setRequest($event)"
      [heading]="heading()"
    ></app-dashboard>
  } @else {
    <section class="width-100 margin-lr-auto content">
      <h2 class="padding-top padding-bottom">
        Add a New Public Records Request
      </h2>
      <mat-tab-group class="gray-border">
        <mat-tab label="Request Details">
          @if (state() === "new-request") {
            <app-request-form #requestForm></app-request-form>
          } @else if (state() === "request-details") {
            <app-request-details [requestId]="requestId"></app-request-details>
          }
        </mat-tab>

        <mat-tab label="Workflow">
          <section class="margin-top content">
            <h3>Selected Reviewers</h3>
            <app-table
              #reviewerTable
              [title]="'reviewers'"
              [data]="reviewers"
              [displayedColumns]="reviewerColumns"
              [canDelete]="true"
            ></app-table>
            <button
              mat-flat-button
              class="margin-sm-top margin-bottom secondary-button"
              (click)="openDialog()"
            >
              Add Reviewer
            </button>
          </section>

          <section class="margin-top margin-bottom content flex-column">
            <h3>General Counsel</h3>
            <app-table
              #counselTable
              [title]="'counsel'"
              [data]="counsel"
              [displayedColumns]="counselColumns"
              [counselData]="counselData"
              [canDelete]="true"
            ></app-table>

            <mat-form-field class="width-sm-50 margin-sm-top">
              <mat-label>{{ counselLabel }}</mat-label>
              <mat-select
                name="counselName"
                [formControl]="counselName"
                [disabled]="allDataAdded(this.counselData, this.counsel)"
              >
                @for (counselName of counselData; track counselName.username) {
                  @if (!counsel.includes(counselName)) {
                    <mat-option [value]="counselName"
                      >{{ counselName.firstName }}
                      {{ counselName.lastName }}</mat-option
                    >
                  }
                }
              </mat-select>
            </mat-form-field>

            <button
              mat-flat-button
              (click)="addCounsel(counselName.value)"
              class="margin-sm-top secondary-button"
              [disabled]="allDataAdded(this.counselData, this.counsel)"
            >
              Add Counsel
            </button>
          </section>
        </mat-tab>

        <mat-tab label="Payment">
          <section class="margin-top content">
            <h3>Invoices</h3>
            <app-table
              #invoiceTable
              [title]="'invoices'"
              [data]="invoices"
              [displayedColumns]="invoiceColumns"
              [canEdit]="true"
              [invoiceForm]="invoiceForm"
            ></app-table>
            <app-invoice-form
              #invoiceForm
              (addInvoice)="addInvoice($event)"
              [showInvoiceForm]="showInvoiceForm"
            ></app-invoice-form>
          </section>
        </mat-tab>
      </mat-tab-group>
    </section>
    <div class="flex-row margin-top margin-bottom content">
      <button
        mat-flat-button
        type="submit"
        class="secondary-button"
        [disabled]="!requestForm()?.requestGroup?.valid"
        (click)="onSubmit(requestForm()?.requestGroup?.value)"
        extended
      >
        Add Request
      </button>
      <button
        mat-stroked-button
        class="regents margin-left"
        (click)="requestForm()?.requestGroup?.reset(); state.set('dashboard')"
      >
        Cancel
      </button>
    </div>
  }
</div>
