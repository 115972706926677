import { Component, input } from '@angular/core';
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'

@Component({
  selector: 'app-table',
  imports: [
    MatTableModule,
    MatSortModule
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {

  searched: boolean = false

  data = input<any>()

  displayedColumns = input<any>()
}
