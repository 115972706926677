export const environment = {
  production: false,
  server: 'publicrecordsrequest-test.iowaregents.edu',
  protocol: 'https://',
  apiUrl: "https://publicrecordsrequest-test.iowaregents.edu/api/",
  counselEndpoint: "Counsel",
  reviewersEndpoint: "Reviewers",
  rolesEndpoint: "Roles",
  requestsEndpoint: "Requests",
  usersEndpoint: "Users",
  usersRoleEndpoint: "UsersRole"
};


