<div id="toolbar-nav-container" [class]="userService.institution()?.key">
  <mat-toolbar [class]="userService.institution()?.key + '-tabs'">
    <mat-nav-list
      aria-label="main-navigation"
      class="desktop-list flex-row inline-flex"
      [class]="userService.institution()?.key + '-tabs'"
    >
      @for (navListItem of navList(); track navListItem.id) {
        <a
          mat-list-item
          title="{{ navListItem.title }}"
          [routerLink]="[navListItem.routerLink]"
          routerLinkActive="isActive"
        >
          {{ navListItem.name }}
        </a>
      }
    </mat-nav-list>
  </mat-toolbar>
</div>
