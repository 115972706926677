import { Routes } from '@angular/router';
import { IntakeComponent } from './intake/intake.component';
import { PublicationComponent } from './publication/publication.component';
import { SettingsComponent } from './settings/settings.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'intake',
    pathMatch: 'full',
  },
  {
    path: 'intake',
    title: 'Intake & Response',
    component: IntakeComponent,
  },
  {
    path: 'publication',
    title: 'Publication',
    component: PublicationComponent,
  },
  {
    path: 'settings',
    title: 'System Settings',
    component: SettingsComponent,
  },
  {
    path: '**',
    redirectTo: 'intake',
  },
];
