<section class="flex-row center margin-lr-auto margin-top">
  @if (state() === "dashboard") {
    <app-dashboard (stateChanged)="state.set('new-user')"></app-dashboard>
  } @else {
    <section class="flex-row center margin-lr-auto margin-top">
      <mat-tab-group>
        <mat-tab label="Manage Users">
          @if (state() === "new-user") {
            <app-new-user></app-new-user>
          } @else if (state() === "user-details") {
            <app-user-details></app-user-details>
          }
        </mat-tab>
      </mat-tab-group>
    </section>
  }
</section>
