<span class="flex-row space-between">
  <h3 mat-dialog-title autofocus class="margin">{{ data().title }}</h3>
  <button
    mat-button
    class="regents margin-sm-top margin-sm-right"
    extended
    matDialogClose
  >
    <mat-icon class="material-symbols-outlined margin-lr-auto">
      close
    </mat-icon>
  </button>
</span>
<mat-dialog-content class="flex-column">
  <p class="margin-bottom">{{ data().subtitle }}</p>

  <app-user-form
    #userForm
    [reviewers]="data().reviewers"
    [reviewerTable]="data().reviewerTable"
    [reviewerData]="data().reviewerData"
    [institutionData]="data().institutionData"
    [roleData]="data().roleData"
    [state]="data().state"
  ></app-user-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button class="regents" extended matDialogClose>
    Cancel
  </button>

  <button
    mat-stroked-button
    (click)="addUser(userForm.userGroup)"
    class="regents"
    [disabled]="!userForm.userGroup.valid"
    extended
    matDialogClose
  >
    Add Reviewer
  </button>
</mat-dialog-actions>
