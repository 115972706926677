<table mat-table [dataSource]="data()" matSort class="loaded-table">

    @for (column of displayedColumns(); track column) {
        <ng-container [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
      }

    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
</table>
