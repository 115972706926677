import { Component, inject } from '@angular/core';
import { TabsComponent } from '../tabs/tabs.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-brand-bar',
  imports: [MatToolbarModule, MatButton],
  templateUrl: './brand-bar.component.html',
  styleUrl: './brand-bar.component.scss',
})
export class BrandBarComponent {}
