import { Component, inject, OnInit } from '@angular/core';
import { TabsComponent } from '../tabs/tabs.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { CommunityAuthService } from '../../services/authorization/community-auth.service';
import { delay, distinctUntilChanged, tap } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { CsiNgBrandHeaderComponent } from '@csi-ng-components/csi-ng-brand-header';
import { NavList } from '../../models/nav-list';

@Component({
  selector: 'app-brand-bar',
  imports: [
    MatToolbarModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CsiNgBrandHeaderComponent,
    TabsComponent,
  ],
  templateUrl: './brand-bar.component.html',
  styleUrl: './brand-bar.component.scss',
})
export class BrandBarComponent implements OnInit {
  public authenticated: boolean = false;
  isImpersonating: boolean | undefined = false;
  public accountName: string | undefined = undefined;
  authService = inject(CommunityAuthService);
  router = inject(Router);
  userService = inject(UserService);

  siteName = {
    id: '1',
    siteName: 'Public Records Processing',
    siteUrl: '',
  };

  navList: NavList[] = [
    {
      id: '1',
      title: 'Intake & Response',
      name: 'Intake & Response',
      hrefLink: '',
      routerLink: 'intake',
      clickLink: ''
    },
    {
      id: '2',
      title: 'Publication',
      name: 'Publication',
      hrefLink: '',
      routerLink: 'publication',
      clickLink: ''
    },
    {
      id: '3',
      title: 'System Settings',
      name: 'System Settings',
      hrefLink: '',
      routerLink: 'settings',
      clickLink: ''
    },
  ];

  ngOnInit() {
    this.authService.isAuthenticated$
      .pipe(
        delay(25), // enough to prevent "Expression has changed after it was checked."
        distinctUntilChanged(),
        tap(this.authChange.bind(this)),
      )
      .subscribe();
  }

  authChange(a: boolean) {
    console.log('auth changed', a, this.authService.actualUsername);

    // real auth
    this.authenticated = a;

    // test auth
    // this.authenticated = true;
    
    this.isImpersonating = this.authService.isImpersonating;
    this.accountName = this.authService.actualUsername;
  }

  public logout() {
    delete localStorage['X-SignIn-InstitutionHint'];
    this.authService.logout();
    this.router.navigate(['logged-out']);

    // test auth
    // this.authenticated = false;
  }

  public login() {
    this.router.navigate(['login']);
  }
}
