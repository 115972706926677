import {
  Component,
  computed,
  inject,
  input,
  model,
  output,
  Signal,
  WritableSignal,
} from '@angular/core';
import { SearchInputComponent } from '../widgets/search-input/search-input.component';
import { ExpansionPanelComponent } from '../widgets/expansion-panel/expansion-panel.component';
import { TableComponent } from '../widgets/table/table.component';
import { ButtonToggleGroupComponent } from '../widgets/button-toggle-group/button-toggle-group.component';
import { MatButton } from '@angular/material/button';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  imports: [
    SearchInputComponent,
    ExpansionPanelComponent,
    TableComponent,
    ButtonToggleGroupComponent,
    MatButton,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  private location = inject(Location);

  stateChanged = output<void>();

  route: string = this.location.path().split('/')[1];

  buttonLabel: Signal<string> = computed(() =>
    this.route === 'intake' ? 'New Request' : 'New User',
  );
}
