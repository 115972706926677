<div class="mat-elevation-z0 table-container">
  <table mat-table [dataSource]="dataSource" matSort class="loaded-table">
    @for (column of displayedColumns(); track column) {
      <ng-container [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>
          {{ getColumnHeader(column) }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [class.small-table-cell]="column === 'delete' || column === 'edit'"
        >
          @if (column === "delete") {
            <button
              mat-button
              (click)="deleteRow(element)"
              class="flex-row flex-end"
            >
              <mat-icon
                class="material-symbols-outlined margin-lr-auto delete-button"
              >
                close
              </mat-icon>
            </button>
          } @else if (column === "edit") {
            <button
              class="flex-row flex-end edit-button"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Edit"
            >
              <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editRow(element)">Edit</button>
              <button mat-menu-item (click)="deleteRow(element)">Delete</button>
            </mat-menu>
          } @else {
            @if (column === "requester") {
              <span class="flex-column">
                {{ element[column] }}
                <span class="cool-gray">{{
                  getName(element["affilliation"], "affilliation")
                }}</span>
              </span>
            } @else if (column === "affilliation" && title() !== "requests") {
              <span class="flex-column">
                {{ element["department"] }}
                <span class="cool-gray">{{
                  getName(element["institution"], "institution")
                }}</span>
              </span>
            } @else if (column === "amount") {
              {{ element[column] | currency }}
            } @else if (isDate(element[column])) {
              {{ element[column] | date: "shortDate" }}
            } @else {
              {{ getColumnValues(element, column) }}
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns()"
      (click)="changeState('request-details', row.requestId)"
    ></tr>
  </table>

  @if (dataSource.filteredData.length === 0 && title() === "requests") {
    <p class="flex-row center margin-sm-top">
      <b>No {{ title() }} found.</b>
    </p>
  } @else if (dataSource.filteredData.length === 0 && title() !== "requests") {
    <p class="flex-row center margin-sm-top">
      <b>No {{ title() }} added.</b>
    </p>
  }
</div>
@if (title() === "requests") {
  <mat-paginator
    [length]="dataSource.filteredData.length"
    [pageSizeOptions]="[5, 10]"
    [pageSize]="5"
    aria-label="Select page of requests"
    class="margin-top"
  ></mat-paginator>
}
