<section class="flex-row center margin-lr-auto margin-top">
  <mat-tab-group>
    <mat-tab label="Unpublished Requests">
      <app-dashboard></app-dashboard>
    </mat-tab>
    <mat-tab label="Published Requests">
      <app-dashboard></app-dashboard>
    </mat-tab>
    <mat-tab label="Publication Details">
      <app-dashboard></app-dashboard>
    </mat-tab>
  </mat-tab-group>
</section>
