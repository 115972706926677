<form [formGroup]="requestGroup" class="flex-column" class="regents">
  <section class="flex-column margin-top content">
    <h3>Requester</h3>
    <mat-divider class="margin-bottom"></mat-divider>
    <span class="flex-row space-between">
      <mat-form-field class="width-sm-50 margin-sm-bottom">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="requesterFirstName" required />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="width-sm-50 margin-sm-bottom padding-left">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="requesterLastName" required />
        <mat-error>Required</mat-error>
      </mat-form-field>
    </span>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Email</mat-label>
      <input matInput formControlName="requesterEmail" required />
      @if (requestGroup.get("requesterEmail")?.hasError("required")) {
        <mat-error>Required</mat-error>
      }
      @if (requestGroup.get("requesterEmail")?.hasError("email")) {
        <mat-error>Invalid email</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Affilliation</mat-label>
      <input matInput formControlName="affilliation" required />
      <mat-error>Required</mat-error>
    </mat-form-field>
  </section>

  <section class="flex-column content">
    <h3 class="margin-sm-top">Request</h3>
    <mat-divider class="margin-bottom"></mat-divider>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Institution</mat-label>
      <mat-select formControlName="institution" name="institution">
        @for (institution of institutionData; track institution.value) {
          <mat-option [value]="institution.value">{{
            institution.name
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-md-75 margin-sm-bottom">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" required />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="width-md-75 margin-sm-bottom">
      <mat-label>Details</mat-label>
      <textarea
        formControlName="description"
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="10"
        cdkAutosizeMaxRows="50"
      ></textarea>
      <mat-error>Request must be at least 10 characters long</mat-error>
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category" name="category">
        @for (category of categoryData; track category.value) {
          <mat-option [value]="category.value">{{ category.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Date Initiated</mat-label>
      <input
        matInput
        formControlName="dateInitiated"
        [matDatepicker]="dateInitiatedPicker"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateInitiatedPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #dateInitiatedPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Reviewer Date Due</mat-label>
      <input
        matInput
        formControlName="reviewerDateDue"
        [matDatepicker]="reviewerDateDuePicker"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="reviewerDateDuePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #reviewerDateDuePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Date Due</mat-label>
      <input
        matInput
        formControlName="dateDue"
        [matDatepicker]="dateDuePicker"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateDuePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #dateDuePicker></mat-datepicker>
    </mat-form-field>
  </section>

  <section class="flex-column margin-sm-top content">
    <h3 class="margin-sm-top">Publication</h3>
    <mat-divider class="margin-bottom"></mat-divider>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Public Status</mat-label>
      <mat-select
        formControlName="status"
        name="status"
        #publicStatus
        (selectionChange)="updateField(publicStatus.value)"
      >
        @for (status of statusData; track status.value) {
          <mat-option [value]="status.value">{{ status.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-sm-50 margin-sm-bottom">
      <mat-label>Date Completed</mat-label>
      <input
        matInput
        [matDatepicker]="dateCompleted"
        formControlName="dateCompleted"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateCompleted"
      ></mat-datepicker-toggle>
      <mat-datepicker #dateCompleted></mat-datepicker>
    </mat-form-field>

    <mat-label class="margin-sm-top">Response Time</mat-label>
    <mat-radio-group
      aria-labelledby="Response Time"
      class="width-sm-50 margin-sm-bottom margin-sm-top flex-column"
      formControlName="responseTimeCount"
    >
      @for (count of responseTimeCountData; track count.value) {
        <mat-radio-button [value]="count.value">{{
          count.name
        }}</mat-radio-button>
      }
    </mat-radio-group>
  </section>
</form>
