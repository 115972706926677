import { Component, inject, signal, WritableSignal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';
import { FormBuilder, Validators } from '@angular/forms';
import { NewUserComponent } from '../shared/components/new-user/new-user.component';
import { UserDetailsComponent } from '../shared/components/user-details/user-details.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-settings',
  imports: [
    MatTabsModule,
    DashboardComponent,
    NewUserComponent,
    UserDetailsComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {
  fb = inject(FormBuilder);
  private location = inject(Location);

  state: WritableSignal<string> = signal('dashboard');

  route: string = this.location.path().split('/')[1];

  userGroup = this.fb.group(
    {
      identifyingGroup: this.fb.group({
        username: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        institution: ['', Validators.required],
        department: ['', Validators.required],
      }),
      contactGroup: this.fb.group({
        phone: ['', Validators.required],
        alternatePhone: ['', Validators.required],
        email: ['', Validators.required],
        alternateEmail: ['', Validators.required],
      }),
      roleGroup: this.fb.group({
        role: ['', Validators.required],
        active: ['', Validators.required],
      }),
    },
    { updateOn: 'blur' },
  );
}
